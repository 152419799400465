import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
    ThemeProvider,
    VideoPlayer as VideoPlayerComponent,
    slicingSuggestionsToChaptersTransformer,
    slicingSuggestionsToThumbnailsTransformer,
    stepsToCTAsTransformer
} from '@guidde/design-system'
import { type MediaPlayerInstance } from '@vidstack/react'

import {
    AnyPlaybookType,
    videoPlayerDefaultState,
    VideoOverlayDataType,
    PlaylistType
} from 'app/types'

import {
    CollectVideoPlayAnalytics,
    useBoolean,
    useCtaReport,
    useQuery,
    useVideoLoadAnalytics
} from 'hooks'
import { host, isQG } from 'modules'

import { Feedback } from 'components/Feedback'
import {
    checkToOpenPlaybookPauseScreen,
    PauseScreen
} from 'components/PauseScreen'

import {
    logToAnalytics,
    playbookToAnalyticsProps,
    generatePlaybookURL,
    copyToClipboard
} from 'modules'

const copyPlaybookLink = ({
    playbook,
    callback
}: {
    playbook: AnyPlaybookType
    callback: () => void
}) => {
    const playbookURL = generatePlaybookURL(playbook)
    return copyToClipboard(playbookURL, callback)
}

type Props = {
    overlayData: VideoOverlayDataType
    videos: Array<AnyPlaybookType>
    playlist?: PlaylistType
}

export const VideoPlayer = ({ videos, overlayData, playlist }: Props) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0)

    const playbook = videos?.[currentVideoIndex] ?? null

    const isPlayerMounted = useBoolean()
    const setPlayerMounted = isPlayerMounted.setTrue
    const playerRef = useRef(videoPlayerDefaultState)
    const setPlayerRef = useCallback(
        (element: MediaPlayerInstance) => {
            setPlayerMounted()

            playerRef.current = {
                getPlayingState: () => !element.paused,
                getDuration: () => element.duration,
                // Important to call element functions in such way, as function reference might change
                pause: () => {
                    element.pause()
                },
                addEventListener: (...args) => {
                    element.addEventListener(...args)
                },
                removeEventListener: (...args) => {
                    element.removeEventListener(...args)
                },
                eventNames: {
                    play: 'play',
                    pause: 'pause',
                    timeUpdate: 'time-update'
                },
                getCurrentTime: () => element.currentTime
            }
        },
        [setPlayerMounted]
    )

    const query = new URLSearchParams(useLocation()?.search)
    const autoPlay = query.get('autoPlay') === 'true'

    const $publicFF = useQuery(
        `/c/v1/feature-flags-public?orgId=${playbook.uploadedByOrgId}`,
        {
            method: 'GET'
        }
    )

    const showPauseScreen = useBoolean()
    const hasShowedPauseScreen = useBoolean()
    const resetHasShowedPauseScreen = hasShowedPauseScreen.setFalse
    useEffect(
        () => resetHasShowedPauseScreen(),
        [playbook.id, resetHasShowedPauseScreen]
    )

    const isPlaylist = Boolean(playlist)
    const formattedPlaylist =
        (isPlaylist &&
            videos?.map((video: any) => ({
                source: video?.url,
                poster: video?.screenshotUrl,
                subtitles: [
                    {
                        src: video?.subtitlesUrl,
                        label: 'English',
                        language: 'en-US',
                        default: true
                    }
                ],
                chapters: slicingSuggestionsToChaptersTransformer(
                    video?.slicingSuggestion ?? []
                ),
                thumbnails: slicingSuggestionsToThumbnailsTransformer(
                    video?.slicingSuggestion ?? []
                ),
                ctas: stepsToCTAsTransformer((video?.steps as any) ?? [], host),
                title: video?.title
            }))) ||
        []

    const animationUrl = isQG(playbook) ? playbook.previewUrl : playbook.gifUrl
    const video = {
        source: playbook?.url,
        poster: animationUrl,
        subtitles: playbook?.subtitles?.length
            ? playbook.subtitles
            : [
                  {
                      src: playbook?.subtitlesUrl,
                      label: 'English',
                      language: 'en-US',
                      default: true
                  }
              ],
        chapters: playbook?.chapters?.length
            ? playbook?.chapters
            : slicingSuggestionsToChaptersTransformer(
                  playbook?.slicingSuggestion ?? []
              ),
        thumbnails: slicingSuggestionsToThumbnailsTransformer(
            playbook?.slicingSuggestion ?? []
        ),
        ctas: stepsToCTAsTransformer((playbook?.steps as any) ?? [], host),
        title: playbook?.title
    }

    const analyticsProps = useMemo(
        () => playbookToAnalyticsProps(playbook, playlist),
        [playbook, playlist]
    )

    const { reportEvent: reportCtaClick } = useCtaReport({
        playbook,
        videoPlayer: playerRef.current,
        playlist
    })

    useVideoLoadAnalytics({ playbook })
    useEffect(() => {
        if (playlist) {
            logToAnalytics('open_playlist', {
                'playlist-app':
                    playlist.applications?.[0]?.applicationName || null,
                'playlist-appId':
                    playlist.applications?.[0]?.applicationId || null,
                'playlist-tags': playlist.tags,
                'playlist-id': playlist.id,
                'playlist-title': playlist.title
            })
        }
    }, [playlist])

    if ($publicFF.isValidating) {
        return null
    }

    return (
        <>
            {isPlayerMounted.isTrue && (
                <CollectVideoPlayAnalytics
                    playbook={playbook}
                    videoPlayer={playerRef.current}
                />
            )}

            <ThemeProvider>
                <VideoPlayerComponent
                    {...(isPlaylist
                        ? { playlist: formattedPlaylist }
                        : { video })}
                    playerProps={{
                        playerRef: setPlayerRef,

                        isAutoplay: autoPlay,
                        isMuted: autoPlay,

                        onPause: () => {
                            checkToOpenPlaybookPauseScreen({
                                videoPlayer: playerRef.current,
                                overlayData,
                                openPauseScreen: () => {
                                    hasShowedPauseScreen.setTrue()
                                    showPauseScreen.setTrue()
                                },
                                isPauseScreenShowed: hasShowedPauseScreen.isTrue
                            })

                            logToAnalytics('videoStopClicked', analyticsProps)
                        },
                        onPlay: () => {
                            logToAnalytics('videoPlayClicked', analyticsProps)
                        },

                        onMute: () => {
                            logToAnalytics('videoMuted', analyticsProps)
                        },
                        onUnmute: () => {
                            logToAnalytics('videoUnmuted', analyticsProps)
                        },

                        // onSpeedChange: speed => {},

                        // onCaptionsChange: language => {},

                        hasChapterNav: true,

                        hasTitle: true,

                        // @ts-ignore
                        hasShareButton: !$publicFF.data?.hideButtonShareInEmbed,
                        shareButtonPosition: 'right',
                        onShare: cb => {
                            const target = isPlaylist ? 'playlist' : 'pb'
                            logToAnalytics(
                                `share_${target}_btn_clicked`,
                                analyticsProps
                            )

                            copyPlaybookLink({
                                playbook: playlist ?? playbook,
                                callback: () => {
                                    logToAnalytics('copyLink', analyticsProps)
                                    cb?.()
                                }
                            })
                        },

                        hasFullscreenMode: true,
                        onFullscreenModeToggle: () => {
                            logToAnalytics(
                                'videoFullscreenClicked',
                                analyticsProps
                            )
                        },
                        hasPIPMode: true,
                        onPIPModeToggle: () => {
                            logToAnalytics('videoPipClicked', analyticsProps)
                        },

                        hasCTAs: true,
                        onCtaClick: reportCtaClick,

                        // @ts-ignore
                        hasWatermark: !$publicFF.data?.canHideWatermarkEmbed,

                        hasPauseScreen: true,
                        pauseScreenSlot: showPauseScreen.isTrue && (
                            <PauseScreen
                                playbook={playbook}
                                onClose={showPauseScreen.setFalse}
                            />
                        ),

                        hasBranding: true,
                        brandLogoSrc: overlayData?.brandKit.logo,

                        hasEndScreen: true,
                        endScreenSlot: (
                            <Feedback
                                playbook={playbook}
                                brandKitColor={overlayData?.brandKit.color}
                            />
                        ),

                        onPlaylistNav: (direction, videoIndex) => {
                            if (typeof videoIndex !== 'undefined') {
                                setCurrentVideoIndex(videoIndex)
                            }
                            // if (direction === 'prev') {
                            // } else {
                            // }
                        }
                    }}
                />
            </ThemeProvider>
        </>
    )
}

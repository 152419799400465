import { getAuth } from 'firebase/auth'
import { envConfig } from './envConfig'
import {
    AnyPlaybookType,
    PlaylistType,
    QuickGuiddeType,
    SingleCtaType
} from 'app/types'
import { generatePath } from 'react-router-dom'
import { paths } from 'app/paths'

const PRODUCTION_PROJECT = 'guidde-production'
export const host = `https://${envConfig.firebaseConfig.authDomain}`

export const isDev = envConfig.firebaseConfig.projectId !== PRODUCTION_PROJECT
export const isLocalhost = process.env.NODE_ENV === 'development'

export const isQG = (
    playbook: AnyPlaybookType
): playbook is QuickGuiddeType => {
    return playbook.mode === 'quickGuidde'
}

export const isPlaylist = (
    playbook: AnyPlaybookType
): playbook is PlaylistType => {
    return playbook.mode === 'playlist'
}

export const delay = (time: number) =>
    // @ts-ignore
    new Promise(resolve => setTimeout(resolve, time)).then(clearTimeout)

export const jsonError = 'RESPONSE_IN_NOT_JSON'

export const request = async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    body?: any
) => {
    const token = await getAuth().currentUser?.getIdToken()

    if (!token) return

    return fetch(`${host}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-client': 'embed',
            authorization: `Bearer ${token}`
        },
        ...(body && { body: JSON.stringify(body) })
    }).then(async res => {
        if (!res.ok) {
            try {
                return res.json().then(err => {
                    return Promise.reject(err)
                })
            } catch (e) {
                return Promise.reject('Something went wrong')
            }
        }
        return Promise.resolve(res.json())
    })
}

export const option = (value: any, label: any) => ({ value, label })

export const uniqueArray = (a: Array<any>) =>
    // @ts-ignore
    [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const round = (number: number) => Math.round(number * 100) / 100

export function formatTime(seconds: number) {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

export const formatDate = (seconds: number) => {
    return new Date(seconds * 1000).toString().substring(4, 16)
}

export const timeToSeconds = (time?: string) => {
    if (!time) return 0

    return time
        .split(':')
        .reverse()
        .reduce((prev: any, curr: any, i) => prev + curr * Math.pow(60, i), 0)
}

export const percentToTime = (duration: number, percent = 0) =>
    (duration / 100) * percent

export const tagRegex = new RegExp(/^[a-zA-Z0-9_.-]*$/)

export const domainValidation = (domains: string) =>
    new RegExp(
        `^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(${domains})$`
    )

export const uiId = (id: string) => `ui-t-${id}`

export const getUserBrowser = () => {
    const sUsrAg = navigator.userAgent
    let sBrowser

    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'firefox'
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = 'samsung_internet'
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = 'opera'
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'ie'
    } else if (sUsrAg.indexOf('Edg') > -1 || sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'edge'
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'chromium'
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'safari'
    } else {
        sBrowser = 'unknown'
    }
    return sBrowser
}

export const copyToClipboard = (data: string, callback: () => void) => {
    if (!navigator?.clipboard) {
        return Promise.reject({ message: 'Clipboard API not supported' })
    }

    return navigator.clipboard
        .writeText(data)
        .then(callback)
        .catch(e => console.error('COPY LINK ERROR:', e))
}

export const isColorLight = (color: string) => {
    const hex = color.replace('#', '')

    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)

    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 155
}

type OpenType = '_blank' | '_self'
export const openLink = (link: string, type: OpenType = '_blank') => {
    window.open(link, type)
}

export const generateUrlWithDomain = (path: string) => {
    return `https://${envConfig.firebaseConfig.authDomain}${[path]}`
}

export const getCtaLink = (cta: SingleCtaType['action']) => {
    return !cta.link.startsWith('http://') && !cta.link.startsWith('https://')
        ? `https://${cta.link}`
        : cta.link
}

export const isMobileDevice = () => {
    return [
        /Android/i,
        /BlackBerry/i,
        /iPhone|iPad|iPod/i,
        /Opera Mini/i,
        /IEMobile/i,
        /WPDesktop/i
    ].some(it => navigator?.userAgent?.match(it))
}

export const fetchTranscript = async (url: string, callback: any) => {
    const response = await fetch(url)
    const data = await response.text()

    callback(JSON.parse(data))
}

export const getOS = () => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (/Linux/.test(platform)) {
        os = 'Linux'
    }

    return { os }
}

export const detectBrowserWithVersion = () => {
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let nameOffset, verOffset, ix

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 4)
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browserName = 'Google Chrome'
        fullVersion = nAgt.substring(verOffset + 7)
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browserName = 'Mozilla Firefox'
        fullVersion = nAgt.substring(verOffset + 8)
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
    ) {
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
        fullVersion = fullVersion.substring(0, ix)
    if ((ix = fullVersion.indexOf(' ')) != -1)
        fullVersion = fullVersion.substring(0, ix)

    const majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion)
    }

    return {
        browser: browserName,
        browserVersion: fullVersion
    }
}

export const generatePlaybookURL = ({
    isPlaylist,
    isPublic,
    id: playbookId
}: AnyPlaybookType) => {
    const playbookPath = generatePath(
        !isPublic ? paths.playbookDetails : paths.sharePlaybook,
        { playbookId }
    )

    const playlistPath = generatePath(
        !isPublic ? paths.playlistDetails : paths.sharePlaylist,
        { playlistId: playbookId }
    )

    const copyLinkPath = isPlaylist ? playlistPath : playbookPath

    return generateUrlWithDomain(copyLinkPath)
}

import { memo, useMemo, useState } from 'react'

import { PlaybookFeedback } from '@guidde-co/shared.playbook-feedback'

import { AnyPlaybookType } from 'app/types'

import { useBoolean, useNotification, useWindowView } from 'hooks'

import {
    generateUrlWithDomain,
    logToAnalytics,
    openLink,
    request,
    generatePlaybookURL
} from 'modules'

import { links } from 'app/links'
import { generateUtmParams } from './ActionButton'
import { Box } from '@mui/material'

const source = 'embed'
type Props = {
    playbook: AnyPlaybookType
    brandKitColor?: string
}

export const Feedback = memo(({ playbook, brandKitColor }: Props) => {
    const feedbackLoading = useBoolean()

    const { showErrorNotification } = useNotification()

    const { xsDown } = useWindowView()

    const $sendFeedback = (comment: string) => {
        feedbackLoading.setTrue()

        return request('/b/v1/send-comment', 'POST', {
            playbookId: playbook.id,
            playbookURL: generatePlaybookURL(playbook),
            comment
        })
            .catch(e => {
                console.error('[EMBED SEND FEEDBACK]:', e)
                showErrorNotification('Something went wrong, please try later')
            })
            .finally(feedbackLoading.setFalse)
    }

    const utmParams = useMemo(() => {
        const query = new URLSearchParams()

        const params = generateUtmParams({
            playbookId: playbook.id,
            uploadedByOrgId: playbook.uploadedByOrgId,
            isRestricted: false
        })

        Object.entries(params).map(([key, value]) => query.append(key, value))

        return query.toString()
    }, [playbook])

    const openSignUpPage = () => {
        const url = generateUrlWithDomain('/signup?' + utmParams)

        openLink(url)
    }

    const buttonColor = brandKitColor

    const onSingUpClick = (feedback: string) => {
        logToAnalytics('post_comment_anonymouslyX', {
            feedback,
            source
        })
        openSignUpPage()
        $sendFeedback(feedback)
    }

    const [isOpened, setIsOpened] = useState(true)

    if (!isOpened) {
        return null
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                color: '#212121',
                '& > div': {
                    background: 'none'
                }
            }}
        >
            <PlaybookFeedback
                isLoading={feedbackLoading.isTrue}
                onClose={() => {
                    setIsOpened(false)
                }}
                authorName={playbook.uploadedBy?.displayName || ''}
                maxWidth={xsDown ? undefined : '450px'}
                authorPhotoURL={playbook.uploadedBy?.photoURL}
                background={buttonColor}
                onPostClick={feedback => {
                    logToAnalytics('post_comment_clicked', {
                        feedback,
                        source
                    })
                }}
                onSingUpClick={onSingUpClick}
                onPostAnonymously={async feedback => {
                    logToAnalytics('post_comment_anonymously', {
                        feedback,
                        source
                    })

                    return await $sendFeedback(feedback)
                }}
                hidePolicyAndTermsLink
                termsLink={links.terms}
                privacyPolicyLink={links.privacyPolicy}
                isMobileView={xsDown}
            />
        </Box>
    )
})
